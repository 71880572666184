// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6EMcd5cfDd8_RhoGgNvFfHts8LC4iroY",
  authDomain: "farmingseo13clone.firebaseapp.com",
  projectId: "farmingseo13clone",
  storageBucket: "farmingseo13clone.firebasestorage.app",
  messagingSenderId: "517533834671",
  appId: "1:517533834671:web:70cc31035eb58513be235a"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;